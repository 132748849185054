import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layouts/blog';
import { divider } from '../assets/stylesheets/blog.module.scss';

export default function BlogPostTemplate(props) {
  const post = props.data.markdownRemark;
  const {
    title,
    description,
    created_at: createdAt,
    updated_at: updatedAt,
  } = post.frontmatter;

  // purgecss: mx-auto my-12 mb-12 sm:w-3/4 updates
  return (
    <Layout title={title} description={description || post.excerpt}>
      <h1 className="title" dangerouslySetInnerHTML={{ __html: title }} />
      <div className={divider}></div>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <div className={divider}></div>
      <div className="date">
        <span>
          {createdAt}
          {createdAt !== updatedAt ? ` (Updated ${updatedAt})` : ''}
        </span>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        slug
        created_at(formatString: "MMMM DD, YYYY")
        updated_at(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

BlogPostTemplate.propTypes = {
  data: PropTypes.object,
};
