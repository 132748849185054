import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import LinkButton from './link-button';
import logo from '../assets/images/logo.svg';
import * as styles from '../assets/stylesheets/components/simple-nav.module.scss';

export default function SimpleNav({ type }) {
  return (
    <nav className={styles.nav}>
      <div>
        <Link to="/" className={styles.logo}>
          <div>
            <img src={logo} alt="Regan Chan" />
          </div>
          <div>Regan Chan</div>
        </Link>
      </div>
      {type === 'posts' && (
        <div>
          <LinkButton href="/#posts" className="m-auto">
            All Posts
          </LinkButton>
        </div>
      )}
      {type === 'projects' && (
        <div>
          <LinkButton href="/#projects" className="m-auto">
            All Projects
          </LinkButton>
        </div>
      )}
    </nav>
  );
}

SimpleNav.propTypes = {
  type: PropTypes.string,
};
