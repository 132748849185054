import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { link } from '../assets/stylesheets/components/link-button.module.scss';

const LinkButton = ({ children, href }) => {
  return (
    <Link to={href} className={link}>
      {children}
    </Link>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

export default LinkButton;
